<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/academies')"
        />
      </template>
    </Toolbar>
    <div class="form-card" id="print">
      <div class="mb-3 text-center">
        <div class="align-items-center">
          <div class="profile-img-edit align-items-center">
            <img
              :src="body.image"
              class="profile-pic height-150 width-150 align-items-center"
              style="width: 100px;height: 100px;"
              @error="$errorHandler"
            />
            <div class="p-image">
              <div class="position-relative">
                <i class="ri-pencil-line upload-button"></i>
                <b-form-file
                  class="h-100 position-absolute"
                  accept="image/*"
                  style="opacity: 0;"
                  @change="previewImage"
                ></b-form-file>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-4">
          <div class="mb-3">
            <label for="userName" class="form-label text-center">
              اسم المستخدم
            </label>

            {{ body.userName }}
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3">
            <label for="email" class="form-label text-center">
              البريد الالكتروني
            </label>

            {{ body.email }}
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3">
            <label for="phone" class="form-label text-center">
              الهاتف
            </label>
            {{ body.phone }}
          </div>
        </div>
      </div>
      <DataTable
        ref="dt"
        :value="list"
        class="p-datatable-customers"
        dataKey="id"
        :rowHover="true"
        :expandedRows.sync="expandedRows"
      >
        <template #header>
          <div class="table-header">
            قائمه اللاعبين

            <div style="text-align:left">
              <!-- <MultiSelect
                :value="selectedColumns"
                :options="columns"
                optionLabel="header"
                @input="onToggle"
                placeholder="اختيار الاعمده"
                style="width: 20em"
              /> -->
            </div>
          </div>
        </template>
        <template #empty>
          لا يوجد بيانات
        </template>
        <template #loading>
          يتم تحميل البيانات. يرجي الانتظار...
        </template>

        <Column
          field="image"
          header="الصوره الشخصيه"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <img
              :src="$baseUploadURL + slotProps.data.image"
              class="dt-image"
            />
          </template>
        </Column>
        <Column field="leaguesId.name" header="الدوري" :sortable="true" />

        <Column field="name" header="الاسم" :sortable="true" />
        <Column field="nameEn" header="الاسم بالانجليزيه" :sortable="true" />
        <Column field="schoolName" header="اسم المدرسه" :sortable="true" />
        <Column field="nationalID" header="الرقم القومي" :sortable="true" />
        <Column field="dateOfBirth" header="تاريخ الميلاد" :sortable="true" />
        <Column field="gender" header="النوع" :sortable="true">
          <template #body="slotProps">
            {{ slotProps.data.gender == 'male' ? 'ذكر' : 'انثي' }}
          </template>
        </Column>

        <!-- <Column :expander="true" headerStyle="width: 3rem" /> -->

        <template #expansion="slotProps">
          <div class="orders-subtable">
            <DataTable :value="[slotProps.data]" class="p-datatable-customers">
              <!-- <Column
              field="image"
              header="الصوره الشخصيه"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.image"
                  class="dt-image"
                />
              </template>
            </Column> -->

              <Column
                field="image"
                header="البطاقه الشخصيه"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.data.nationalIdCard"
                    class="dt-image"
                    v-if="slotProps.data.nationalIdCard"
                  />
                  <p v-else>
                    لا يوجد
                  </p>
                </template>
              </Column>

              <Column
                field="image"
                header="شهاده الميلاد"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.data.birthCertificate"
                    class="dt-image"
                  />
                </template>
              </Column>

              <Column
                field="image"
                header="اثبات القيد"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.data.constraintProve"
                    class="dt-image"
                  />
                </template>
              </Column>

              <Column
                field="image"
                header="بطاقه الام"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.data.motherCard"
                    class="dt-image"
                  />
                </template>
              </Column>

              <Column
                field="image"
                header="بطاقه الاب"
                bodyStyle="text-align: center; overflow: visible"
              >
                <template #body="slotProps">
                  <img
                    :src="$baseUploadURL + slotProps.data.fatherCard"
                    class="dt-image"
                  />
                </template>
              </Column>
            </DataTable>
            <DataTable
              :value="slotProps.data.teams"
              class="p-datatable-customers"
            >
              <Column field="yearsAcademieId.yearId.name" header="السنه" />
              <Column field="name" header="الفرقه" />
            </DataTable>
          </div>
        </template>
      </DataTable>

      <DataTable
        ref="dt2"
        :value="listCoach"
        class="p-datatable-customers"
        dataKey="id"
        :rowHover="true"
      >
        <template #header>
          <div class="table-header">
            قائمه المدربين

            <div style="text-align:left">
              <!-- <MultiSelect
                :value="selectedColumns"
                :options="columns"
                optionLabel="header"
                @input="onToggle"
                placeholder="اختيار الاعمده"
                style="width: 20em"
              /> -->
            </div>
          </div>
        </template>
        <template #empty>
          لا يوجد بيانات
        </template>

        <Column
          field="image"
          header="الصوره الشخصيه"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <img
              :src="$baseUploadURL + slotProps.data.image"
              class="dt-image"
            />
          </template>
        </Column>
        <Column field="name" header="الاسم" :sortable="true" />
        <Column field="leaguesId.name" header="الدوري" :sortable="true" />

        <Column field="nationalID" header="الرقم القومي" :sortable="true" />
      </DataTable>

      <DataTable
        ref="dt3"
        :value="list"
        class="p-datatable-customers"
        dataKey="id"
        :rowHover="true"
      >
        <template #header>
          <div class="table-header">
            قائمه الادريين

            <div style="text-align:left">
              <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            /> -->
            </div>
          </div>
        </template>
        <template #empty>
          لا يوجد بيانات
        </template>

        <Column
          field="image"
          header="الصوره الشخصيه"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <img
              :src="$baseUploadURL + slotProps.data.image"
              class="dt-image"
            />
          </template>
        </Column>
        <Column field="name" header="الاسم" :sortable="true" />
        <Column field="leaguesId.name" header="الدوري" :sortable="true" />

        <Column field="nationalID" header="الرقم القومي" :sortable="true" />
      </DataTable>

      <DataTable
        ref="dt"
        :value="listTechnicalManager"
        class="p-datatable-customers"
        dataKey="id"
        :rowHover="true"
      >
        <template #header>
          <div class="table-header">
            قائمه المديرين الفنين

            <div style="text-align:left">
              <!-- <MultiSelect
                :value="selectedColumns"
                :options="columns"
                optionLabel="header"
                @input="onToggle"
                placeholder="اختيار الاعمده"
                style="width: 20em"
              /> -->
            </div>
          </div>
        </template>
        <template #empty>
          لا يوجد بيانات
        </template>

        <Column
          field="image"
          header="الصوره الشخصيه"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <img
              :src="$baseUploadURL + slotProps.data.image"
              class="dt-image"
            />
          </template>
        </Column>
        <Column field="name" header="الاسم" :sortable="true" />
        <Column field="leaguesId.name" header="الدوري" :sortable="true" />

        <Column field="nationalID" header="الرقم القومي" :sortable="true" />
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        image: '',
        email: null,
        password: null,
        phone: null,
        userNotes: null,
      },
      id: null,
      list: [],
      listCoach: [],
      listMindful: [],
      columns: [
        {
          field: 'image',
          header: 'الصوره',
        },

        {
          field: 'leaguesId',
          header: 'الدوري',
        },
        {
          field: 'academieId',
          header: 'الاكاديميه',
        },
        {
          field: 'name',
          header: 'الاسم',
        },
        {
          field: 'nameEn',
          header: 'الاسم بالانجليزيه',
        },

        {
          field: 'schoolName',
          header: 'اسم المدرسه',
        },

        {
          field: 'nationalID',
          header: 'الرقم القومي',
        },

        {
          field: 'dateOfBirth',
          header: 'تاريخ الميلاد',
        },

        {
          field: 'gender',
          header: 'النوع',
        },
      ],
      expandedRows: [],
      listTechnicalManager: [],
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`academies/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.image = this.$baseUploadURL + res.data.image;
          this.$http.get(`players?academieId=${this.id}`).then((response) => {
            this.list = response.data;
            this.expandedRows = this.list.filter((p) => p.id);
          });
          this.$http.get(`coach?academieId=${this.id}`).then((response) => {
            this.listCoach = response.data;
          });
          this.$http.get(`mindful?academieId=${this.id}`).then((response) => {
            this.listMindful = response.data;
          });
          this.$http
            .get(`technicalManager?academieId=${this.id}`)
            .then((response) => {
              this.listTechnicalManager = response.data;
            });
        },

        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style>
.p-image {
  width: 0;
}
.p-image .custom-file-input {
  text-align: center;
  width: 89vw;
  position: absolute;
  top: -99px;
  height: 105px;
}
</style>
